﻿//genericos

.app-header {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $c-ter-700;
    font-weight: $fw-heavy;
    font-size: 20px;
    margin-bottom: 1rem;
    height: 64px;
    border-bottom: 2px solid $c-ter-200;

    .info {

        div {
            display: inline-flex;
            align-items: center;
        }

        .title {
            margin-left: 2rem;
        }

        .step-back {
            width: 64px;
            height: 64px;
            border-right: 2px solid $c-ter-200;
            justify-content: center;
            font-size: 26px;
            cursor: pointer;
            padding: 0px 33px;
            margin-right: 1rem;
        }
    }


}

.container {
    max-width: 820px;
    margin: 0 auto;
}

.whitebox {
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(105, 153, 176, 0.12);
    width: 100%;
    background-color: #fff;
    padding: 1rem;
}

.bullet {
    width: 12px;
    height: 12px;
    border: solid 1px $c-ter-500;
    border-radius: 8px;
}


//eventos
.event-list {
    background-color: $c-ter-200;

    .events-grid {
        justify-content: center;

        .event-box {
            width: 238px;
            padding-bottom: 1rem;
            margin: 1rem;


            .event-item {
                display: inline-flex;
                cursor: pointer;
                width: 100%;

                .header-image {
                    width: 100%;
                    margin-bottom: 1rem;
                    border-top-left-radius: 16px;
                    border-top-right-radius: 16px;


                    img {
                        object-fit: cover;
                        height: 268px;
                        border-top-left-radius: 16px;
                        border-top-right-radius: 16px;
                    }

                    i {
                        font-size: 50px;
                    }
                }

                .event-info {
                    text-align: center;

                    div {
                        margin-bottom: .2rem;
                    }

                    .event-name {
                        font-size: 15px;
                        font-weight: 800;
                        line-height: 1.33;
                        text-align: center;
                        color: $c-ter-700;
                    }

                    .event-date {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 1.25;
                        text-align: center;
                        color: $c-ter-700;

                        span {
                            font-size: 12px;

                            &::before {
                                content: "·";
                                margin: 0 5px;
                            }
                        }
                    }

                    .event-site {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 1.25;
                        text-align: center;
                        color: $c-ter-700;
                    }
                }
            }


        }
    }


    //interior evento
    .event-body {
        margin-top: 3rem;

        //home
        .box-event-options {
            width: 100%;
            height: 200px;
            border-radius: 8px;
            box-shadow: 0 2px 4px 0 rgba(105, 153, 176, 0.12);
            background-color: #fff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            cursor: pointer;

            .options-img {
                width: 68px;
                height: 68px;
                border-radius: 100px;
                font-size: 1.9em;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $c-purple;
                background-color: rgba($c-purple, 0.15);
            }

            .options-text {
                width: 120px;
                margin-top: 2rem;
                font-size: 15px;
                font-weight: $fw-heavy;
                text-align: center;
                color: $c-ter-700;
            }


            &.box-2 {
                .options-img {
                    color: $c-sucs;
                    background-color: rgba($c-sucs, 0.15);
                }
            }

            &.box-3 {

                .options-img {
                    color: $c-orange;
                    background-color: rgba($c-orange, 0.15);
                }
            }
        }


        //accesos
        .box-access-site {
            border-radius: 8px;
            box-shadow: 0 2px 4px 0 rgba($c-sec-light, 0.12);
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            div {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .option-img-access {
                width: 68px;
                height: 68px;
                margin: 20px;
                border-radius: 100px;
                color: $c-purple;
                background-color: rgba($c-purple, 0.15);
                font-size: 1.9em;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .option-img-room {
                width: 68px;
                height: 68px;
                border-radius: 100px;
                margin: 20px;
                color: $c-sucs;
                background-color: rgba($c-sucs, 0.15);
                font-size: 1.9em;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .option-text {
                font-size: 15px;
                font-weight: $fw-bold;
                color: $c-ter-700;
                margin-left: 1rem;
            }

            .option-text-arrow {
                font-weight: $fw-bold;
                color: $c-ter-700;
                font-size: 2rem;
                margin-right: 1rem;
            }
        }


        //aforos
        .div-control-aforo {

            .box-tit-control {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .control-tit-site {
                    font-size: 20px;
                    font-weight: $fw-bold;
                    color: $c-ter-700;
                }

                .option-aforo {
                    font-size: 14px;
                    font-weight: $fw-bold;
                    color: $c-ter-700;

                    .aforo-tit {
                        color: $c-ter-700;
                        margin-right: .5rem;
                    }

                    .aforo-data {
                        font-weight: $fw-bold;
                        text-align: right;
                        color: $c-ter-500;

                        span {
                            color: $c-ter-700;
                        }
                    }
                }

            }


            .box-div {
                border-radius: 8px;
                box-shadow: 0 2px 4px 0 rgba(105, 153, 176, 0.12);
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 1rem;

                &.to-enter {
                    .iconuser-toEnter {
                        color: $c-redlight;
                        font-size: 3rem;
                    }

                    .guest-toEnter {
                        font-size: 20px;
                        font-weight: $fw-bold;
                        color: $c-redlight;
                    }
                }

                &.into {
                    .iconuser-into {
                        color: $c-greenlight;
                        font-size: 3rem;
                    }

                    .guest-into {
                        width: 35px;
                        height: 24px;
                        margin: 0 3px 0 0;
                        font-size: 20px;
                        font-weight: $fw-bold;
                        color: $c-greenlight;
                    }
                }

                .guest-total {
                    width: 34px;
                    height: 17px;
                    margin: 6px 0 1px 3px;
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 1.29;
                    text-align: left;
                    color: #93a6b9;
                }

            }


            .box-site {
                border-radius: 8px;
                box-shadow: 0 2px 4px 0 rgba(105, 153, 176, 0.12);
                background-color: #fff;
                cursor: pointer;
                padding: 1rem 0 1rem 1rem;
                width: 100%;
                margin-bottom: 2rem;

                div {
                    align-items: center;
                }

                .option-img-location {
                    font-size: 1.8rem;
                }

                .option-title {
                    font-size: 15px;
                    font-weight: $fw-bold;
                    color: $c-ter-700;
                }

                .option-sites {
                    font-size: 15px;
                    font-weight: $fw-bold;
                    color: $c-ter-700;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .option-box {
                    border-radius: 6px;
                    border: solid 1px $c-ter-300;
                    background-color: #fff;
                    text-align: center;
                    display: flex;
                    margin: .5rem;
                    font-size: 14px;

                    &.libres {
                        .bullet {
                            margin-left: 10px;
                        }

                    }

                    .box-sites {
                        display: inline-block;
                        text-align: left;
                        padding: 0.5rem 1.2rem;
                    }

                    .text {
                        font-size: 14px;
                        color: $c-ter-700;
                    }

                    .text-2 {
                        color: $c-ter-700;
                    }

                    .text-3 {
                        color: $c-ter-500;
                    }

                    .text-4 {
                        font-weight: $fw-bold;
                        color: $c-ter-700;
                    }

                    .iconuser {
                        width: 13px;
                        height: 14.6px;
                        margin-left: 10px;
                        color: $c-redlight;
                        font-size: 1rem;
                    }

                    .text-5 {
                        color: $c-redlight;
                    }

                    .iconuser2 {
                        width: 14px;
                        height: 14.6px;
                        margin-left: 10px;
                        color: $c-greenlight;
                        font-size: 1rem;
                    }

                    .text-6 {
                        color: $c-greenlight;
                    }
                }

                .option-text-arrow {
                    font-weight: $fw-bold;
                    color: $c-ter-700;
                    font-size: 2rem;
                    margin-right: 10px;
                }
                .mobile {
                    display: none;
                }
            }
        }


        //scanner
        .scan-page {

            .placeholder-scanner {
                text-align: center;
                display: flex;
                flex-direction: column;

                i {
                    font-size: 9rem;
                    color: $c-ter-500;
                }

                .text-scan {
                    margin-top: 2rem;
                }

                .btn-scan {
                    margin: 2rem auto 3rem auto;
                }
            }


        }

        .scan-results {

            .icon-status {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 6rem;
                line-height: 1;
                color: $c-error;
                text-align: center;

                h3 {
                    margin-top: 1.5rem;
                    margin-bottom: 0;
                }

                &.confirmado {
                    color: $c-sucs;

                    h3 {
                        color: $c-sucs;
                    }
                }

                &.incorrecto {
                    color: $c-orange;

                    h3 {
                        color: $c-orange;
                    }
                }

                &.rechazado {
                    color: $c-error;

                    h3 {
                        color: $c-error;
                    }
                }
            }

            .warning-acceso {
                background-color: rgba($c-error, .12);
                border-radius: 8px;
                padding: 1.5rem;
                display: flex;
                justify-content: flex-start;

                .fticon-alert {
                    font-size: 30px;
                    margin-right: 15px;
                    color: $c-error;
                }

                p {
                    margin-bottom: 0;
                }
            }

            .entrada {
                padding: 2rem 1rem;
                margin-bottom: 2rem;
                text-align: center;

                .ubicacion {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: fit-content;
                    min-width: 60%;
                    margin: 1rem auto 0 auto;
                    font-weight: $fw-bold;
                    color: $c-ter-700;

                    div {
                        width: 100%;
                    }

                    .box {
                        padding: 11px;
                        border-radius: 4px;
                        border: solid 1px $c-ter-400;
                        margin-bottom: 1rem;
                        display: flex;
                        align-items: center;

                        .bullet {
                            width: 16px;
                            height: 16px;
                            border-radius: 20px;
                            margin-right: 1rem;
                        }

                    }

                    .fila,
                    .butaca {
                        border-bottom: solid 1px $c-ter-400;
                        padding: 11px 8px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    .butaca {
                        border-bottom: none;
                    }
                }
            }

        }


    }

}

.map-body {

    .border-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 6px;
        width: 100%;
        background-color: #fff;
        padding: 1rem 2rem;
        border: solid 1px $c-ter-300;

        div {
            color: $c-ter-600;

            span {
                color: $c-ter-700;
                font-weight: $fw-bold;
            }
        }

        .bullet {
            margin-right: .5rem;
        }

        .ocupados {
            i {
                color: $c-redlight;
                margin-right: .4rem;
            }
        }

        .libres {
            i {
                color: $c-greenlight;
                margin-right: .4rem;
            }
        }

        .total,
        .ocupados,
        .libres {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .map-scroll {
        width: 100%;
        height: calc(100vh - 160px);
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .map-canvas {
            position: relative;
            user-select: none;
            -webkit-transition: .5s transform ease-in-out;
            -moz-transition: .5s transform ease-in-out;
            -o-transition: .5s transform ease-in-out;
            transition: .5s transform ease-in-out;

            .area-elem {
                position: relative;
                position: absolute;
                user-select: auto;
                display: inline-block;
                top: 0px;
                left: 0px;
                cursor: auto;
                box-sizing: border-box;
                flex-shrink: 0;

                .area-container {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    padding: 10px 10px 10px 10px;

                    .cont-header {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        position: relative;
                        z-index: 55;

                        .cont-name {
                            padding: 1px;
                            width: fit-content;
                            display: flex;
                            align-items: center;
                            max-width: 50%;
                            margin-right: 1rem;

                            &.cont-planos {
                                margin-left: 20px;
                            }

                            .seat-name {
                                font-weight: bold;
                                margin-right: 10px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            .seat-count {
                                background-color: $c-ter-600;
                                padding: 0 5px;
                                border-radius: 8px;
                                color: #fff;
                                font-size: 11px;
                            }
                        }

                        .aforo {
                            display: flex;
                            align-items: center;
                            font-size: 12px;
                            color: $c-ter-600;


                            .total {
                                display: flex;
                                align-items: center;
                                margin-right: 1rem;
                            }

                            .sala {
                                display: flex;
                                align-items: center;
                                margin-right: 1rem;
                            }

                            span {
                                font-weight: $fw-bold;
                                color: $c-ter-700;
                            }

                            .bullet {
                                background-color: #fff;
                                border: 1px solid $c-ter-500;
                                border-radius: 50%;
                                display: flex;
                                height: 8px;
                                margin-right: 0.7rem;
                                width: 8px;

                                &.green {
                                    background-color: $c-sucs-dark;
                                    border-color: $c-sucs-dark;
                                }
                            }
                        }

                    }

                    .cont-cont {
                        height: calc(100% - 50px);
                        margin-top: 10px;

                        .area-row {
                            display: flex;
                            align-items: center;
                            width: fit-content;

                            .area-row-fila {
                                width: 20px;
                                height: 23px;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                span {
                                    font-size: 9px;
                                    color: $c-ter-500;
                                }
                            }

                            .area-row-seat {
                                width: 20px;
                                height: 23px;
                                display: flex;
                                align-items: center;
                            }

                            .row-seat {
                                width: 16px;
                                height: 16px;
                                margin: 2px;
                                display: inline-flex;
                                align-items: center;
                                color: $c-ter-500;

                                span {
                                    display: flex;
                                    font-size: 8px;
                                    justify-content: center;
                                    align-items: flex-end;
                                    width: 100%;

                                    .fticon-tick {
                                        font-size: 5px;
                                        position: relative;
                                        left: -1px;
                                        top: 1px;
                                    }
                                }

                                &.seat {
                                    border: solid 1px $c-ter-400;
                                    border-radius: 50%;
                                }

                                &.space {
                                    border: solid 1px transparent;
                                    background-color: transparent;
                                }


                                &.nula {
                                    border: solid 1px transparent;
                                    background-color: transparent;

                                    span {
                                        display: none;
                                    }

                                    &::after {
                                        font-family: "fonticon";
                                        content: '\e808';
                                        font-size: 15px;
                                        color: $c-ter-400;
                                        position: relative;
                                        top: 0.5px;
                                    }
                                }

                                &.handicap {
                                    background-color: $c-reduced;
                                    border: solid 1px $c-reduced!important;

                                    span {
                                        color: #fff;
                                    }
                                }



                                &.seat-select {
                                    cursor: pointer;

                                    &.space {
                                        cursor: pointer;
                                    }
                                }

                                &.seat-select-ev {
                                    cursor: pointer;

                                    &.space {
                                        cursor: default;
                                    }
                                }

                                &.seat-select-sitting {
                                    cursor: pointer;

                                    &.cant-seat {
                                        cursor: not-allowed;
                                    }

                                    &.space {
                                        cursor: default;
                                    }
                                }

                            }
                        }

                        .area-screen {
                            color: $c-ter-700;
                            background-color: $c-ter-400;
                            width: 100%;
                            height: 100%;
                            min-height: 22px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-weight: $fw-bold;
                            font-size: 11px;
                            text-transform: uppercase;
                        }

                    }


                }

            }

        }
    }

}

//modal asistentes
.ast-modal {
    padding: 1.5rem;

    .ast-filter {

        .row-status {
            margin-bottom: 2rem;

            .input-type-checkbox {
                margin: 0;
                width: 100%;

                .form-label {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }

    }

    .ast-list {

        .table-header {
            .input-type-checkbox {
                margin-left: 10px;
            }
        }

        .asistentes-list {
            max-height: calc(100vh - 240px);
            overflow-y: auto;

            .asistente {
                margin-right: 5px;
                margin-left: 5px;

                .asistente-row {
                    margin-bottom: .7rem;
                    border-radius: 4px;
                    padding: 13px 10px;
                    background-color: $c-ter-200;
                    font-size: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                    user-select: none;

                    &.expanded {
                        box-shadow: 0 1px 7px 0 rgba(147, 166, 185, 0.5);
                        background-color: #fff;
                        margin: 0 auto 1rem auto;
                        position: relative;

                        // &::before {
                        //     content: '\25B6';
                        //     position: absolute;
                        //     color: $c-sec;
                        //     left: -1px;
                        //     font-size: 18px;
                        //     top: 50%;
                        //     transform: translateY(-50%);
                        // }
                    }

                    .name {
                        display: flex;
                        align-items: center;

                        .input-type-checkbox {
                            margin-right: 1rem;
                        }

                        .no-check {
                            width: 32px;
                        }

                        span {
                            font-weight: $fw-bold;
                        }

                        .name-margin{
                            margin-left: 10px;
                        }
                    }

                    .info {
                        display: flex;

                        .seat {
                            width: 30px;
                        }

                        .totals {
                            color: $c-ter-600;
                            letter-spacing: 2px;
                            width: 50px;
                            text-align: right;

                            span {
                                font-weight: $fw-bold;
                                color: $c-ter-700;
                            }

                        }
                    }
                }

                .asistente-info {
                    padding: 0 10px;
                    opacity: 0;
                    -moz-transition: opacity 0.3s ease-in-out;
                    -o-transition: opacity 0.3s ease-in-out;
                    -webkit-transition: opacity 0.3s ease-in-out;
                    transition: opacity 0.3s ease-in-out;
                    overflow: hidden;
                    height: 0;

                    &.opened {
                        opacity: 1;
                        height: fit-content;
                        margin-bottom: 1rem;
                    }

                    .w-entrada {
                        display: flex;
                        align-items: center;
                        background-color: rgba($c-error, .12);
                        border-radius: 8px;
                        padding: 12px 20px 12px 10px;
                        margin-bottom: 1rem;

                        i {
                            font-size: 30px;
                            color: $c-error;
                            margin-right: .5rem;
                        }

                        p {
                            margin-bottom: 0;
                        }
                    }

                    .sitting-type {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 1rem;
                        margin-top: .2rem;
                        padding: 0 8px 0 6px;

                        .name {
                            i {
                                margin-right: .5rem;
                            }
                        }

                        .info {
                            font-size: 13px;

                            span {
                                font-weight: $fw-bold;
                                color: $c-ter-700;
                            }
                        }
                    }

                    .w-seatinfo {
                        border-radius: 6px;
                        background-color: rgba($c-sec-light, .16);
                        padding: 10px 20px;
                        margin-bottom: 1rem;

                        .head {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .sala {
                                font-weight: $fw-bold;
                            }

                            .zona {
                                font-weight: $fw-bold;
                                color: $c-sec;
                                font-size: 13px;
                                margin-left: 20px;
                            }
                        }

                        .asientos {
                            display: flex;
                            align-items: center;
                            // justify-content: space-between;
                            padding: 7px 5px;
                            border-top: 1px solid $c-ter-400;

                            div {
                                color: $c-ter-600;
                                font-size: 13px;
                                letter-spacing: .2px;

                                span {
                                    color: $c-ter-700;
                                    font-weight: $fw-bold;

                                    .blue {
                                        color: $c-sec;
                                    }
                                }
                            }

                            .name-fila{
                                margin-right: 40px;
                            }
                        }

                        &.steps {
                            padding-top: 8px;

                            .input-type-step {
                                .mb-3 {
                                    margin-bottom: 0 !important;
                                }
                            }

                            .step-row {
                                align-items: center;

                                .sala {
                                    font-weight: $fw-bold;
                                }

                                .zona {
                                    font-weight: $fw-bold;
                                    color: $c-sec;
                                    font-size: 12px;
                                }
                            }
                        }

                    }

                    .section-save {
                        text-align: right;
                    }

                }

            }

        }

        .no-results {
            text-align: center;

            p,
            h3 {
                margin-bottom: 1.2rem;
                color: $c-ter-600;
            }
        }

        .icon-table {
            width: 36px;
            height: 36px;
            border-radius: 6px;
            background-color: $c-ter-200;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $c-ter-600;
            line-height: 0;
        
            span {
                    font-size: 11px;
            }
        
            &.icon-entrada {
                font-size: 17px;

                    &.dentro {
                            color: $c-sucs-dark;
                            background-color: rgba($c-sucs-dark, .16);
                    }
        
                    &.porentrar{
                            background-color: rgba($c-error, .14);
                            color: $c-error;
                    }
            }
        }
    }
}


//---------------- MOBILE

@media (max-width: 552px) {

    .event-list {
        .events-grid {
            .event-box {
                width: 100%;
            }

        }
    }

    .event-list {
        .event-body {
            .div-control-aforo {
                .box-site {
                    padding: 1rem;

                    .option-sites {
                        flex-direction: column;
                        margin-top: 1rem;

                        .option-box {
                            width: 100%;

                            .box-sites {
                                padding: 0.2rem 1rem;
                                width: 100%;
                                padding-bottom: 4%;
                                padding-top: 4%;
                                .text{
                                    display: inline-block;
                                }
                                .text-3{
                                    float: right;
                                }
                            }
                        }
                    }
                    .mobile{
                        display: contents;
                    }                

                    .no-mobile {
                        display: none;
                    }
                }
            }
            .div-control-seats{
                .box-div{
                    width: 100%;
                   
                }
            }

            .scan-results {
                .entrada {
                    h1 {
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }

    .map-body {
        .border-box {
            padding: 1rem 0.4rem;
        }
    }

    .ast-modal {
        padding: 1rem;
    }

    .icono-location{
        padding-right: 10%;
    }

    .texto-sala{
        width: 70%;
    }

}
/*----------------------
        ICONOS
------------------------*/

@font-face {
        font-family: 'fonticon';
        src: url('fonts/fonticon.woff2') format('woff2'),
             url('fonts/fonticon.woff') format('woff'),
             url('fonts/fonticon.svg') format('svg');
        font-weight: normal;
        font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
        @font-face {
          font-family: 'fonticon';
          src: url('fonts/fonticon.svg') format('svg');
        }
}

      [class^="fticon-"]:before, [class*=" fticon-"]:before {
        font-family: "fonticon";
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: .2em;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        margin-left: .2em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      

      .fticon-mix-sitting:before { content: '\e800'; } /* '' */
      .fticon-no-sitting:before { content: '\e801'; } /* '' */
      .fticon-sitting:before { content: '\e802'; } /* '' */
      .fticon-star:before { content: '\e803'; } /* '' */
      .fticon-location:before { content: '\e804'; } /* '' */
      .fticon-mail:before { content: '\e805'; } /* '' */
      .fticon-mail-open:before { content: '\e806'; } /* '' */
      .fticon-user:before { content: '\e807'; } /* '' */
      .fticon-forbidden:before { content: '\e808'; } /* '' */
      .fticon-alert:before { content: '\e809'; } /* '' */
      .fticon-scan:before { content: '\e80a'; } /* '' */
      .fticon-handicapped:before { content: '\e80c'; } /* '' */
      .fticon-info:before { content: '\e80d'; } /* '' */
      .fticon-qr:before { content: '\e80e'; } /* '' */
      .fticon-minus:before { content: '\e80f'; } /* '' */
      .fticon-submenu:before { content: '\e813'; } /* '' */
      .fticon-dashboard:before { content: '\e814'; } /* '' */
      .fticon-star-solid:before { content: '\e815'; } /* '' */
      .fticon-message:before { content: '\e816'; } /* '' */
      .fticon-pending:before { content: '\e817'; } /* '' */
      .fticon-state-cancel:before { content: '\e818'; } /* '' */
      .fticon-confirm:before { content: '\e819'; } /* '' */
      .fticon-filter:before { content: '\e81a'; } /* '' */
      .fticon-setting:before { content: '\e81b'; } /* '' */
      .fticon-image:before { content: '\e81c'; } /* '' */
      .fticon-clock:before { content: '\e81d'; } /* '' */
      .fticon-calendar:before { content: '\e81e'; } /* '' */
      .fticon-tick:before { content: '\e81f'; } /* '' */
      .fticon-search:before { content: '\e820'; } /* '' */
      .fticon-trash:before { content: '\e821'; } /* '' */
      .fticon-edit:before { content: '\e822'; } /* '' */
      .fticon-close:before { content: '\e824'; } /* '' */
      .fticon-plus:before { content: '\e825'; } /* '' */
      .fticon-ticket:before { content: '\e826'; } /* '' */
      .fticon-camera:before { content: '\e827'; } /* '' */
      .fticon-arrow-left:before { content: '\e828'; } /* '' */
      .fticon-arrow-right:before { content: '\e829'; } /* '' */
      .fticon-arrow-up:before { content: '\e82a'; } /* '' */
      .fticon-arrow-down:before { content: '\e82b'; } /* '' */
      .fticon-pointer:before { content: '\e82c'; } /* '' */
      .fticon-sort-dsc:before { content: '\e82d'; } /* '' */
      .fticon-seating-area:before { content: '\e82e'; } /* '' */
      .fticon-sort-asc:before { content: '\e82f'; } /* '' */
      .fticon-hand:before { content: '\e830'; } /* '' */
      .fticon-screen:before { content: '\e831'; } /* '' */
      .fticon-map:before { content: '\e832'; } /* '' */
      .fticon-download:before { content: '\e833'; } /* '' */
      .fticon-people:before { content: '\e837'; } /* '' */
      .fticon-move:before { content: '\e839'; } /* '' */
      
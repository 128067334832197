/*-------------------------
    Premieres Mediaset
--------------------------*/

/*----------------------
        RESETS
------------------------*/

* {
        scrollbar-width: thin;
        scrollbar-color: $c-ter-500 #f2f4f7;
}

*::-webkit-scrollbar {
        width: 4px;
        height: 4px;
}

*::-webkit-scrollbar-track {
        background: #f2f4f7;
}

*::-webkit-scrollbar-thumb {
        background-color: $c-ter-500;
        border-radius: 20px;
        border: 0px none #f2f4f7;
}

/*----------------------
        ESTRUCTURA
------------------------*/

html,
body {
        font-family: 'Lato';
        font-weight: normal;
        background-color: #f2f4f7;
        font-size: 14px;
        font-weight: $fw-regular;
        color: $c-ter-700;
}

h1 {
        font-size: 28px;
        line-height: 34px;
}

h2 {
        font-size: 24px;
        line-height: 30px;
}

h3 {
        font-size: 20px;
        line-height: 26px;
}

h4 {
        font-size: 15px;
        line-height: 20px;
}

h1,
h2,
h3,
h4 {
        font-weight: 900;
        color: $c-ter-700;

}

.text-main {
        font-size: 16px;
        font-weight: bold;
}

.text-xs {
        font-size: 12px;
        font-weight: 300;
}

.card {
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 rgba(105, 153, 176, 0.12);
        background-color: #fff;
        border: none;
}

.toast {
        border-radius: 8px;
        box-shadow: 0 2px 10px 0 rgba(8, 32, 66, 0.24);
        background-color: #fff;
}

.bg-gray {
        background-color: $c-ter-200;
}


// NAV HORIZONTAL (Main)

.navbar {

        &.bg-main-bar {
                background-color: #fff;
                height: 64px;
        }

        .navbar-brand {
                padding: 0;
                margin: .2rem 1.5rem 0;
        }

        .logo {
                font-weight: $fw-heavy;
                display: flex;
                align-items: center;

                .logo-full {
                        background: url('./images/logo-full.svg') center center no-repeat;
                        width: 81px;
                        height: 19px;
                }
        }

        &.navbar-light {
                .navbar-nav {
                        .nav-link {
                                font-weight: $fw-heavy;
                                color: $c-ter-700;
                                font-size: 15px;
                                padding: 0 1.5rem;

                                &:hover,
                                &.active {
                                        color: $c-prim;
                                }
                        }
                }
        }

        .dropdown {
                .dropdown-menu {
                        top: 30px !important;
                        right: 15px !important;
                }
        }

}

// NAV PRINCIPAL (vertical) 

.nav-vertical {
        background-color: #fff;
        position: sticky;
        top: 0;
        z-index: 1020;
        padding: 0 7px;

        .nav-container {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                position: sticky;
                top: 0;
                z-index: 1020;

                .nav-icons {
                        text-align: center;
                        align-items: center;
                        justify-content: space-between;
                        flex-wrap: nowrap;
                        margin: 0 auto auto auto;
                        width: 100%;

                        &.nav-pills {

                                .nav-item {
                                        margin-bottom: 2rem;

                                        .nav-link {
                                                border-radius: 4px;
                                                padding: 5px;

                                                i {
                                                        color: $c-ter-700;

                                                        &.fticon-mail {
                                                                font-size: 10px;
                                                                position: relative;
                                                                top: -1px;
                                                                padding-right: 5px;
                                                                padding-left: 2px;
                                                        }
                                                }

                                                &.active,
                                                &:hover {
                                                        background-color: rgba($c-prim, .16);

                                                        i {
                                                                color: $c-prim;
                                                        }
                                                }
                                        }

                                        &.logo {
                                                margin-top: 1rem;
                                                margin-bottom: 3rem;

                                                .nav-link {

                                                        &.active,
                                                        &:hover {
                                                                background-color: transparent;
                                                        }
                                                }

                                                .logo-slim {
                                                        background: url('./images/logo-light.svg') center center no-repeat;
                                                        width: 35px;
                                                        height: 19px;
                                                }
                                        }
                                }
                        }

                }

        }


}

// NAV HORIZONTAL (inPage)

.inpage-nav {

        .nav-item {

                .nav-link {
                        font-weight: $fw-bold;
                        font-size: 14px;
                        color: $c-ter-700;
                        padding: 0.5rem 0;
                        margin: 0 2.5rem 0 0;
                        border-bottom: 2px solid transparent;

                        span {
                                font-weight: $fw-heavy;
                                font-size: 11px;
                                background-color: rgba($c-ter-600, .12);
                                color: $c-ter-600;
                                margin-left: .5rem;
                                border-radius: 9px;
                                padding: 2px 7px 3px 7px;
                        }

                        &:hover {
                                border-bottom: 2px solid $c-ter-400;
                        }


                        &.active {
                                color: $c-prim;
                                border-bottom: 2px solid $c-prim;

                                span {
                                        background-color: rgba($c-prim, .12);
                                        color: $c-prim;
                                }
                        }


                }
        }

}


/*-------------------------
       COMPONENTES
--------------------------*/

// TOAST

.Toastify {
        .Toastify__toast-container {
                width: 388px;

                .Toastify__toast {
                        border-radius: 8px;
                        box-shadow: 0 2px 10px 0 rgba(8, 32, 66, 0.24);
                        font-family: 'Lato';
                        font-weight: $fw-bold;
                        color: $c-ter-700;
                        padding: 28px;

                        .Toastify__toast-icon {
                                font-size: 16px;
                                margin-right: 1rem;

                                .fticon-confirm {
                                        color: $c-sucs;
                                }

                                .fticon-state-cancel {
                                        color: $c-error;
                                }
                        }
                }

        }
}

// STICKERS

.sticker {
        padding: 3px 10px 2px;
        border-radius: 4px;
        border: solid 1px;
        font-size: 13px;
        font-weight: $fw-regular;
        display: inline-flex;
        align-items: center;

        i {
                font-size: 9px;
                margin-left: .5rem;
                margin-top: 2px;
                cursor: pointer;
        }


        &.sticker-orange {
                border-color: transparent;
                background-color: rgba($c-orange, .14);
                color: $c-orange;
        }

        &.sticker-green {
                border-color: transparent;
                background-color: rgba($c-greenforest, .16);
                color: $c-greenforest;
        }

        &.sticker-red {
                border-color: transparent;
                background-color: rgba($c-prim, .14);
                color: $c-prim;
        }

}

// BOTONES

.btn {
        border-radius: 20px;
        font-size: 14px;
        font-weight: bold;
        padding: 9px 28px 10px;
        white-space: nowrap;

        &.btn-icon {
                width: 40px;
                height: 40px;
                display: inline-flex;
                padding: 0;
                justify-content: center;
                align-items: center;
                line-height: 1;
        }
}

.btn-prim-dark {
        background-color: $c-prim;
        border: 1px solid $c-prim;
        outline: none;
        box-shadow: none !important;
        color: #fff;

        &:hover,
        &:focus:hover {
                background-color: $c-prim-dark;
                border-color: $c-prim-dark;
        }

        &:focus {
                background-color: $c-prim;
                border-color: $c-prim;
        }


        &:disabled,
        &.disabled {
                background-color: $c-prim-light;
                color: #fff;
                border-color: $c-prim-light;
        }

}

.btn-prim-light {
        background-color: transparent !important;
        border: 1px solid $c-prim-dark;
        outline: none;
        box-shadow: none;
        color: $c-prim;

        &:hover {
                border-color: $c-prim-dark;
                color: $c-prim-dark;
                box-shadow: 0px 0px 1px 2px rgba($c-prim, .2) !important;
        }

        &:focus {
                border-color: $c-prim-dark;
                color: $c-prim-dark;
                box-shadow: none;
        }

        &:focus:hover {
                box-shadow: 0px 0px 1px 2px rgba($c-prim, .2) !important;
        }


        &:disabled,
        &.disabled {
                border-color: $c-prim-light;
                color: rgba($c-prim-dark, .6);
        }

}

.btn-prim-link {
        background-color: transparent !important;
        border: none;
        outline: none;
        box-shadow: none !important;
        color: $c-prim;

        &:hover,
        &:focus {
                border-color: $c-prim-dark;
                color: $c-prim-dark;
                text-decoration: underline;
        }

        &:disabled,
        &.disabled {
                border-color: $c-prim-light;
                color: rgba($c-prim-dark, .6);
        }

        &.btn-icon {

                &:hover,
                &:focus {
                        background-color: rgba($c-prim, .2) !important;
                        color: $c-prim-dark;
                        text-decoration: none;
                }
        }

}

.btn-inmap {
        background-color: transparent !important;
        border: none;
        outline: none;
        box-shadow: none !important;
        color: $c-ter-700;
        border-radius: 8px;

        &:hover,
        &:focus {
                color: $c-sec-dark;
                text-decoration: underline;
        }

        &:disabled,
        &.disabled {
                border-color: $c-sec-light;
                color: rgba($c-sec-dark, .6);
        }

        &.btn-icon {

                &:focus {
                        color: $c-sec-dark;
                        text-decoration: none;
                }

                &:hover {
                        background-color: rgba($c-sec-dark, .16) !important;
                        color: $c-sec-dark;
                        text-decoration: none;
                }
        }
}


.btn-sec-dark {
        background-color: $c-sec;
        border: 1px solid $c-sec;
        outline: none;
        box-shadow: none !important;
        color: #fff;

        &:hover,
        &:focus:hover {
                background-color: $c-sec-dark;
                border-color: $c-sec-dark;
        }

        &:focus {
                background-color: $c-sec;
                border-color: $c-sec;
        }

        &:disabled,
        &.disabled {
                background-color: $c-sec-light;
                color: #fff;
                border-color: $c-sec-light;
        }

}

.btn-sec-light {
        background-color: transparent !important;
        border: 1px solid $c-sec;
        outline: none;
        box-shadow: none;
        color: $c-sec;

        &:hover {
                border-color: $c-sec-dark;
                color: $c-sec-dark;
                box-shadow: 0px 0px 1px 2px rgba($c-sec, .2) !important;
        }

        &:focus {
                border-color: $c-sec-dark;
                color: $c-sec-dark;
                box-shadow: none;
        }

        &:focus:hover {
                box-shadow: 0px 0px 1px 2px rgba($c-sec, .2) !important;
        }

        &:disabled,
        &.disabled {
                border-color: $c-sec-light;
                color: rgba($c-sec-dark, .6);
        }

}

.btn-sec-link {
        background-color: transparent !important;
        border: none;
        outline: none;
        box-shadow: none !important;
        color: $c-sec;

        &:hover,
        &:focus {
                color: $c-sec-dark;
                text-decoration: underline;
        }

        &:disabled,
        &.disabled {
                border-color: $c-sec-light;
                color: rgba($c-sec-dark, .6);
        }

        &.btn-icon {

                &:focus {
                        color: $c-sec-dark;
                        text-decoration: none;
                }

                &:hover {
                        background-color: rgba($c-sec-dark, .16) !important;
                        color: $c-sec-dark;
                        text-decoration: none;
                }
        }

}

.buttons-groups {
        display: flex;

        &.btn-group>.btn {
                border-radius: 20px !important;
                flex: 1 1 0px;
        }

        &.squared {
                &.btn-group>.btn {
                        border-radius: 8px !important;
                }
        }

        .btn {
                text-align: left;
                border: 1px solid $c-ter-400;
                color: $c-ter-600;
                outline: none !important;
                box-shadow: none !important;
                padding: 9px 28px 10px 18px;

                &.active {
                        border: 1px solid $c-sec;
                        color: $c-sec;

                        &::before {
                                font-family: "fonticon";
                                content: '\e81f';
                                width: 16px;
                                height: 16px;
                                background-color: $c-sec;
                                border-radius: 50%;
                                position: absolute;
                                top: 12px;
                                right: 15px;
                                color: #fff;
                                font-size: 5px;
                                line-height: 1;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding-top: 2px;
                        }
                }

                &:hover {
                        box-shadow: 0px 0px 1px 2px rgba($c-sec, .2) !important;
                }

                &:disabled:is(.active) {
                        opacity: 1;
                        background-color: transparent;
                }

                &:disabled:not(.active) {
                        opacity: 0.5;
                        background-color: transparent;
                }
        }

        &.squared {
                .btn {
                        &.active {

                                &::before {
                                        border-radius: 5px;
                                }

                        }
                }

        }



        .btn:first-child {
                margin-right: 1rem;
        }

        .btn:last-child {
                margin-right: 0;
        }

}

// ALERTAS

.alert {
        color: $c-ter-700;
        border-radius: 8px;
        padding: 27px 42px 25px;
        border: none;
        font-size: 14px;
        font-weight: $fw-regular;
        color: $c-ter-700;
        display: flex;
        align-items: center;

        i {
                font-size: 24px;
                margin-right: 2rem;
        }

        &.alert-success {
                background-color: rgba($c-sucs-dark, .12);

                i {
                        color: $c-sucs-dark;
                }
        }

        &.alert-info {
                background-color: rgba($c-blue, .12);

                i {
                        color: $c-blue;
                }
        }

        &.alert-error {
                background-color: rgba($c-prim-dark, .12);

                i {
                        color: $c-prim-dark;
                }
        }
}

//swal
.swal2-backdrop-show {
        background: rgba($c-ter-600, .2) !important;
}

.swal2-popup {
        border-radius: 8px;
        box-shadow: 0 2px 10px 0 rgba(8, 32, 66, 0.24);
        color: $c-ter-700  !important;
        width: 40em !important;
        padding: 2rem 3rem 3rem !important;

        .swal2-title {
                font-size: 20px;
                font-weight: $fw-heavy;

        }
}

.swal2-actions {
        margin-top: 3rem !important;
}


.swal2-cancel {
        margin-right: 2rem !important;
}

.swal2-confirm {
        color: #fff !important;
}

.swal2-html-container {
        margin: 2em 1.6em 1em;
}

//ddwn 
.dropdown {

        .btn-submenu {
                padding: 0;
                outline: none !important;
                box-shadow: none !important;
                background-color: transparent;
                border: none;
                border-radius: 6px;
                padding: 4px 7px 7px 7px;

                &:hover,
                &:focus,
                &:active,
                &.active {
                        color: $c-prim;
                        background-color: rgba($c-prim, .16);
                }
        }

        .dropdown-menu {
                inset: 5px 0px auto auto !important;
                padding: 10px;
                border-radius: 4px;
                box-shadow: 0 2px 11px 0 rgba(191, 203, 215, 0.35);
                border: solid 1px $c-ter-200;
                background-color: #fff;

        }


        .dropdown-item {
                color: $c-ter-700;
                font-weight: $fw-regular;
                font-size: 13px;
                padding: 12px;
                line-height: 1;
                margin-bottom: .5rem;

                &:hover,
                &:focus,
                &:active,
                &.active {
                        color: $c-ter-700;
                        border-radius: 6px;
                        background-color: rgba($c-sec, .14);
                }

                &:last-child {
                        margin-bottom: 0;
                }
        }
}




// SIDEBAR RIGHT
body.modal-open {
        overflow: hidden;
}

.modal-sidebar {

        .modal.right .modal-dialog {
                position: fixed;
                margin: auto;
                width: 75vw;
                max-width: 420px;
                min-width: 320px;
                height: 100%;
                -webkit-transform: translate3d(0%, 0, 0);
                -ms-transform: translate3d(0%, 0, 0);
                -o-transform: translate3d(0%, 0, 0);
                transform: translate3d(0%, 0, 0);
        }

        .modal.right .modal-content {
                height: 100%;
                overflow: none;
                border: none;
                border-radius: 0;
        }

        .modal.right .modal-body {
                padding: 30px;
                overflow-y: auto;
                margin-bottom: 50px;
                height: calc(100vh - 128px);
        }

        .modal.right.fade .modal-dialog {
                right: -420px;
                -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
                -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
                -o-transition: opacity 0.3s linear, right 0.3s ease-out;
                transition: opacity 0.3s linear, right 0.3s ease-out;
        }

        .modal.right.fade.show .modal-dialog {
                right: 0;
        }

        .modal-header {
                padding: 0;
                height: 64px;
                color: $c-ter-700;
                font-weight: $fw-heavy;
                font-size: 20px;
                border-bottom: 2px solid $c-ter-200;

                div {
                        display: inline-flex;
                        align-items: center;
                }

                .title {
                        padding: 0 1.5rem;
                        width: calc(100% - 68px);
                        white-space: nowrap;
                        overflow: hidden;

                }

                .close {
                        width: 64px;
                        height: 64px;
                        border-left: 2px solid $c-ter-200;
                        justify-content: center;
                        font-size: 12px;
                        cursor: pointer;
                }

                .step-back {
                        width: 64px;
                        height: 64px;
                        border-right: 2px solid $c-ter-200;
                        justify-content: center;
                        font-size: 26px;
                        cursor: pointer;
                        padding: 0px 33px;
                }

                .action-buttons {
                        .btn {
                                margin-right: 1rem;
                        }
                }
        }

        .modal-footer {
                border-radius: 0;
                background-color: $c-ter-200;
                border: none;
                padding: .5rem;
                position: fixed;
                width: 100%;
                right: 0;
                bottom: 0;
        }

        .modal-backdrop.show {
                opacity: .2;
        }

        &.modal-fullwidth {

                .modal.right {
                        .modal-body {
                                height: calc(100vh - 65px);
                                background-color: $c-ter-200;
                        }

                }

        }

}


//tables
.table-header {
        margin: 0;
        font-size: 12px;
        font-weight: $fw-bold;
        text-align: left;
        color: $c-ter-700;

        div:first-child {
                display: inline-flex;
        }

        .spacer-medal {
                width: 56px;
        }

}

.table-row {
        border-radius: 6px;
        border: solid 1px $c-ter-300;
        display: flex;
        align-items: center;
        margin: 0 0 .8rem 0;
        padding: 10px 3px;
        cursor: pointer;

        &.no-pointer {
                cursor: default;
        }

        .col-name {
                div {
                        display: inline-flex;
                }

                .name {
                        overflow: hidden;
                        white-space: nowrap;
                        width: calc(100% - 52px);
                }

                .medal {
                        margin-right: 20px;
                }

        }

        .col-actions {
                display: inline-flex;
                align-items: center;
                justify-content: space-between;

                div {
                        display: inline-flex;
                        justify-content: space-between;
                }

                .date {
                        font-size: 12px;
                        color: $c-ter-600;
                        margin-left: 12px;
                }
        }

        .col-icon {
                text-align: center;

                i {
                        margin-right: .5rem;
                }

                span {
                        font-size: 12px;
                        color: $c-ter-600;
                }
        }

        .sticker {
                margin: 3px;
        }
}


//acordeones
.accordion {


        .accordion-item {
                border: solid 1px $c-ter-300;

                &:first-of-type {
                        border-top-left-radius: 6px;
                        border-top-right-radius: 6px;

                        .accordion-button {
                                border-top-left-radius: 6px;
                                border-top-right-radius: 6px;
                        }
                }


                &:not(:first-of-type) {
                        border-top: 0;
                }

                .accordion-button {
                        font-size: 14px;
                        font-weight: $fw-bold;
                        color: $c-ter-700;
                        padding: .5rem 1.25rem;

                        .delete-div {
                                position: absolute;
                                right: 40px;
                                cursor: pointer;
                                color: $c-prim;
                        }

                        &:focus {
                                outline: none;
                                box-shadow: none;
                                border-color: $c-ter-300;
                                background-color: #fff;
                        }

                        &:not(.collapsed) {
                                color: $c-ter-700;
                                background-color: #fff;
                                box-shadow: inset 0 -1px 0 $c-ter-300;
                        }

                        &::after {
                                content: '\e82b';
                                font-family: "fonticon";
                                background: none;
                                width: auto;
                                height: auto;
                                font-size: 16px;
                        }

                }

        }
}

//popups-tooltip
.popup-content {
        margin: auto;
        background: #fff;
        width: 50%;
        padding: 15px;
        border: 1px solid #fff;
}

[role=tooltip].popup-content {
        width: 200px;
        box-shadow: 0 2px 10px 0 rgba(8, 32, 66, 0.24);
        border-radius: 4px;
}

.popup-overlay {
        background: rgba(0, 0, 0, .5);
}

[data-popup=tooltip].popup-overlay {
        background: transparent;
}

.popup-arrow {
        -webkit-filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, .16));
        filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, .16));
        color: #fff;
        stroke-width: 2px;
        stroke: #fff;
        stroke-dasharray: 30px;
        stroke-dashoffset: -54px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
}

/*-------------------------
        PÁGINAS
--------------------------*/

.page-header {
        display: flex;
        align-items: center;

        h1 {
                margin-bottom: 0;
                font-size: 20px;
        }

        .btn {
                min-width: 137px;
        }

}


//home
.home-page {

        .btn,
        .sticker,
        .medal {
                margin-right: 1rem;
        }

}


.error-page,
.denied-page {
        min-height: 80vh;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        .ball {
                width: 62px;
                height: 62px;
                background-color: $c-prim;
                border-radius: 50%;
                line-height: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto 2rem auto;

                i {
                        color: #fff;
                        font-size: 28px;
                }

        }
}